.home__col-style {
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 3px 14px 23px 1px #2A2A2A;
    padding: 1rem;

    @media (min-width: 768px) {
        width: 400px;
    }
}

.home__layout {
    margin: 0;
    padding: 1rem;
    line-height: 2;
     
    @media (min-width: 768px) {
        margin: 0 auto;
        padding: 3rem 3rem 2rem 3rem;
    }
}

.home__first-container {
    width: 100%;

    @media (min-width: 768px) {
        width: 50%;
    }
}

.home__articles-container {
    width: 100%;

    @media (min-width: 768px) {
        width: 50%;
    }
}

.home__articles-card {
    width: 100%;

    @media (min-width: 768px) {
        width: 500px;
        height: auto;
    }

}

.home__articles-flexbox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (min-width: 768px) {
        flex-direction: row;
    }
}

.home__articles-image-container {

    width: 100%;
    height: 100%;

    .home__articles-image {
        max-width: 100%;

        @media (min-width: 768px) {
            width: 100%;
        }
    }
}

.bg-home {
    background-image: url("https://wirelessengineeringimages.s3.amazonaws.com/BLUE+BGResponsive.png");
    background-size: 100% 100%;

    @media (min-width: 768px) {
        background-image: url("https://wirelessengineeringimages.s3.amazonaws.com/BLUE+BG1.jpg");
        background-size: 100% 100%;
    }

}

.bg-circles {
    background-image: url("https://wirelessengineeringimages.s3.amazonaws.com/bg+circles1.jpg");
    background-size: 100% 100%;
}