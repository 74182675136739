.contact__bg {
    background-image: url("https://wirelessengineeringimages.s3.amazonaws.com/Background+Contactus-R.jpg");
    background-size: 100% 100%;
    height: 100%;

    @media (min-width: 768px) {
        background-image: url("https://wirelessengineeringimages.s3.amazonaws.com/CONTACT+US+BG.jpg");
        background-size: 100% 100%;
    }
}

.contact__error {
    width: 40%;
    color: red;
    font-size: 1.2rem;
}


.contact__form-container {
    width: 100%;
    height: 100%;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    
    h3 {
        font-weight: bold;
        letter-spacing: 2px;
    }
    @media (min-width: 768px) {
        margin-left: 2rem;
        h3 {
            font-size: 1.8rem;
        }
    }
}

.contact__first-container {
    width: 100%;

    @media (min-width: 768px) {
        width: 50%;
    }
}

.contact__input {
    color: black;
    border: 0px;
    border-bottom: 1px solid #131D5C;
    font-size: 20px;
    margin-bottom: 1rem;
    height: 3rem;
    width: 100%;
    border-radius: 10px;

    transition: border-bottom .3s ease;

    &:focus {
        border-bottom: 1px solid black;
        outline: none;
    }

    @media (min-width: 768px) {
        width: 60%;
        height: 50px;
    }
}

.contact__textarea {
    color: #131D5C;
    border-radius: 2px;
    box-shadow: 0 1px 2px #131D5C;
    margin-bottom: 10px;
    font-size: 20px;
    height: 8rem;
    flex: 1 1 auto;
    resize: none;
    border-radius: 10px;
    width: 100%;    
    @media (min-width: 880px) {
        width: 60%;

    }
}

.button-block {
    width: 100%;
    background-color: white;

    @media (min-width: 768px) {
        width: 10rem;
        margin-left: 1.8rem;
    }
}

.button {
    color: #2D4499;
    cursor: pointer;
    border-radius: 4px;
    background-color: white;
    border: none;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 1px;
    padding: 1rem;
    text-transform: uppercase;

    transition: background-color .3s ease;

    &:focus {
        outline: none;
    }

    &:hover {
        background-color: darken($color: #2D4499, $amount: 10);
        color: lighten($color: #2D4499, $amount: 10);
    }

    
    
}