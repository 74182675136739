@media screen and (min-width: 771px) and (max-width: 9999px) {
    .footer__container { display: block !important; }  /* show it on big screens */
}

@media screen and (min-width: 0px) and (max-width: 770px) {
    .footer__container { display: none !important; }   /* hide it elsewhere */
}

.footer__container {
    position: relative;
    background-color: darken($color: #131D5C, $amount: 10);
    min-width: 1000px;
    color: white;

    a {
        text-decoration: none;
    }

    h2 {
        display: block;
        text-align: center;
        padding: 15px 0;
        line-height: 19px;
        font-size: 18px;
        margin: 0;
    }
}

.footer__back-top {
    font-weight: bold;
    color: white;
    background-color: #2D4499;
    padding: 1rem;
    margin-bottom: 4rem;

    transition: background-color 0.3s ease;

    &:hover {
        background-color: darken($color: #131D5C, $amount: 10);
    }


    h2 {
        display: block;
        text-align: center;
        padding: 15px 0;
        line-height: 19px;
        font-size: 18px;
        margin: 0;
    }

}

#navFooter {
    margin-top: 0;
}



.footer__logo-container {
    width: 20rem;
    @media (min-width: 768px) {
        width: 30rem;
    }

    .footer__logo {
        max-width: 100%;
        @media (min-width: 768px) {
            width: 100%;
        }
    }
}

.footer__rights-container {
    font-size: 20px;
    font-weight: bold;
    @media (min-width: 800px) {
        padding: 1rem;
        display: flex;
        justify-content: space-around;
    }
    
    p {
        margin: 0px;
    }
}

.footer__logo-container {

    width: 20rem;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    @media (min-width: 768px) {
        width: 30rem;
    }
    
     
    .footer__logo{
    
        max-width: 100%;
        @media (min-width: 768px) {
            width: 100%;
        }
    }
}

// Responsive Footer Config

@media screen and (min-width: 0px) and (max-width: 770px) {
    .navFooterMobile { 
        display: block; 
        background-color:white;
        
    }  /* show it on small screens */
    
}
    
@media screen and (min-width: 770px) and (max-width: 9000px) {
    .navFooterMobile { display: none; }   /* hide it elsewhere */
}

#nav-footer {
    position: relative;
    width: 100%;
    min-width: 200px;
    font-family: inherit;
    font-size: 12px;
    line-height: 1em;
}


#mblFooter-gototop {
    padding-top: 5px;
    display: block;
    text-align: center;
    height: 50px;

    .navIcon {
        display: inline-block;
        border-style: solid;
        border-width: 5px;
        border-color: transparent;
        border-top-width: 0;
        border-bottom: 5px solid #ccc;
        width: 0;
        height: 0;
        font-size: 0;
        line-height: 0;
    }

    .nav-b {
        display: block;
        color: white;
        font-size: 11px;
        font-weight: 400;
        line-height: 30px;
        text-transform: uppercase;
    }
}

.navFooter-topPage {
    padding-top: 5px;
    position: relative;
    background-color: #2D4499;
    color: white;
    text-decoration: none;
}

.footer__mobileContent {
    color: white;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 1.3rem;
    padding: 1rem 1.5rem 2rem 1.5rem;

    
}

.footer__mobilerights-container {
    display: block;
    text-align: center;
    color: white;
    padding: 1rem;
    font-size: 15px;

    p {
        margin-top: 8px;
    }
}

.footer__mobile-container {
    background-color: darken($color: #131D5C, $amount: 10);
}