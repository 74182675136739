.services__first-bg {
    height: 100%;
    background-image: url("https://wirelessengineeringimages.s3.amazonaws.com/RESPONSIVE+SERVICES.jpg");
    background-size: 100% 100%;

    @media (min-width: 768px) {
        background-image: url("https://wirelessengineeringimages.s3.amazonaws.com/BACKGROUND+SERVICES.jpg");
        background-size: 100% 100%;
        height: 70vh;
    }
}

.services__second-bg {
    height: 100%;
    background-image: url("https://wirelessengineeringimages.s3.amazonaws.com/RESPONSIVE+SERVICES.jpg");
    background-size: 100% 100%;

    @media (min-width: 768px) {
        background-image: url("https://wirelessengineeringimages.s3.amazonaws.com/BACKGROUND+SERVICES2.jpg");
        background-size: 100% 100%;
        height: 70vh;
    }
}

.services__first-container {
    width: 100%;

    @media (min-width: 768px) {
        width: 60%;
    }
}