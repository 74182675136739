.about__bg {
    background-image: url("https://wirelessengineeringimages.s3.amazonaws.com/ABOUT+BGResponsive.jpg");
    background-size: 100% 100%;
    height: 100%;

    @media (min-width: 768px) {
        background-image: url("https://wirelessengineeringimages.s3.amazonaws.com/ABOUT+BG.jpg");
        background-size: 100% 100%;
        height: 70vh;
    }

}

.about__second-bg {
    background-image: url("https://wirelessengineeringimages.s3.amazonaws.com/New+Products+BGResponsive.jpg");
    background-size: 100% 100%;
    height: 100%;

    @media (min-width: 768px) {
        background-image: url("https://wirelessengineeringimages.s3.amazonaws.com/NEW+PRODUCTS+BG.jpg");
        background-size: 100% 100%;
        height: 70vh;
    }

}

.about__founder-flexbox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (min-width: 768px) {
        flex-direction: row;
        justify-content: space-evenly;
    }
}

.about__founder-container {
    width: 100%;

    @media (min-width: 768px) {
        width: 50%;
    }

    // .founder-content {
    //     width: 100%;

    //     @media (min-width: 768px) {
    //         width: 500px;
    //         height: auto;
    //     }

    // }
}

.about__sabino-image-container {
    width: 100%;
    height: 100%;

    .about__sabino-image {
        max-width: 100%;

        @media (min-width: 768px) {
            width: 100%;
        }
    }
}